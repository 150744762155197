// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.
import { AppShellComponentType } from '@priva/appshell';

export const environment = {
    production: true,
    debugState: false,
    offline: false,

    configuration: {
        name: 'Priva Analytics Building',
        showUserName: true,
        showTenantName: true,
        uris: {
            apiService: '/api/v1',
            navigationService: '/api/navigation/from',
            reportsService: '/api/v1/reports',
            profilesService: '/api/v3/profiles',
            accessControlService: '/api/access-control/tenants',
            siteService: '/api/v1/Sites',
            chartsService: '/api/v3/charts',
            smartInsightsService: '/api/v3/smart-insights',
            applicationInfo: '/api/v1/applicationinfo',
            featureToggleService: '/api',
            consolidatedPreferences: '/core-api/v1/preferences/language-preference',
            preferencesGetAvailableLanguages: '/core-api/v1/preferences/available-languages',
        },
        analytics: {
            segment: {
                apiKey: 'tanGqq3l3C8iifkg7UJ5tcIuMEpdrcJo',
                trackInternalUsers: true,
            },
            appInsights: {
                instrumentationKey: '13eed1cd-f13a-4222-a837-e7300813de1a',
            },
        },
        components: {
            appHelp: {
                type: AppShellComponentType.AppHelp,
                behavior: 'service',
            },
            appsOverview: {
                type: AppShellComponentType.AppsOverview,
                behavior: 'service',
                serviceUri: '/api/my-applications',
            },
            userInfo: {
                type: AppShellComponentType.UserInfo,
                behavior: 'service',
                serviceUri: '/bff/user',
            },

            portalShortcut: {
                type: AppShellComponentType.PortalShortcut,
                behavior: 'link',
                pageUri: 'https://apps.mypriva.com',
            },

            signoutShortcut: {
                type: AppShellComponentType.SignoutShortcut,
                behavior: 'link',
                pageUri: '/signout',
            },
        },
    },

    version: '0.1.0',
    buildDate: Date.now(),
};
