import { createAction, props } from '@ngrx/store';

import { ErrorMessage } from '@priva/appshell';
import { CurrentUser } from '@priva/auth/current-user';
import { DynamicComponent } from '@priva/components/dynamic';

import { TenantUserDto, ToasterNotification } from 'app/core/models';

import { FeatureToggleDto } from './../../core/models/feature-toggle.model';

export const logOut = createAction('[App] Log out');

export const openDialog = createAction('[App] Open dialog', props<{ dialog: DynamicComponent }>());
export const closeDialog = createAction('[App] Close dialog');

export const openPanel = createAction('[App] Open panel', props<{ panel: DynamicComponent }>());
export const closePanel = createAction('[App] Close panel');

export const showToaster = createAction('[App] Show toaster', props<{ notification: ToasterNotification }>());
export const removeToaster = createAction('[App] Remove toaster');

export const showThrobber = createAction(
    '[App] Show a throbber',
    props<{ selectorClass: string; loadingMessage?: string }>(),
);
export const clearThrobber = createAction('[App] Clear a throbber', props<{ selectorClass: string }>());
export const clearAllThrobbers = createAction('[App] Clear all throbbers');

export const loadCurrentUser = createAction('[App] Load current user');
export const loadCurrentUserSuccess = createAction(
    '[App] Load current user (success)',
    props<{ currentUser: CurrentUser }>(),
);
export const loadCurrentUserError = createAction(
    '[App] Load current user (error)',
    props<{ error: ErrorMessage }>(),
);
export const updateCurrentUser = createAction(
    '[App] Update current user',
    props<{ currentUser: CurrentUser }>(),
);

export const loadTenantUserList = createAction('[App] Load tenant user list');

export const loadTenantUserListSuccess = createAction(
    '[App] Load tenant user list (success)',
    props<{ tenantUsers: TenantUserDto[] }>(),
);

export const loadTenantUserListError = createAction(
    '[App] Load tenant user list (error)',
    props<{ error: ErrorMessage }>(),
);
export const resetState = createAction('[App] Reset state');
export const nothing = createAction('[App] No action (@effect) required');

export const loadFeatureToggles = createAction('[App] Load feature toggles');
export const loadFeatureTogglesSuccess = createAction(
    '[App] Load feature toggles (success)',
    props<{ featureToggles: FeatureToggleDto[] }>(),
);
export const loadFeatureToggleError = createAction(
    '[App] Load feature toggles (error)',
    props<{ error: ErrorMessage }>(),
);

export const showPageLoader = createAction('[App] Show Page Loader');
export const hidePageLoader = createAction('[App] Hide Page Loader');
